import { onPageLoad } from './onPageLoad';
import { SurveyModel, PageModel } from 'survey-core';

let previousPageNumber: number;

export const surveyAfterRenderPage = (
  survey: SurveyModel,
  { page, htmlElement }: { page: PageModel; htmlElement: HTMLElement },
) => {
  if (previousPageNumber !== page.num) {
    onPageLoad(survey, { page, htmlElement });
    previousPageNumber = page.num;
  }
};
