import { Route, Routes } from 'react-router-dom';
import Form from './Form';
import * as Sentry from '@sentry/react';
import { sentryConfig } from './sentry-config';
import { useSetUtmParametersFromAffiliateTrackingLinkOnMount } from './hooks/useSetUtmParamtersFromAffiliateTrackingLinkOnMount';

Sentry.init(sentryConfig);
const SentryRoutes = Sentry.withSentryReactRouterV6Routing(Routes);

const App = () => {
  useSetUtmParametersFromAffiliateTrackingLinkOnMount();

  return (
    <SentryRoutes>
      <Route path="*" element={<Form />} />
    </SentryRoutes>
  );
};

export default App;
