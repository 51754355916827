export interface ICustomerBackendGeneratedAttributes {
  ID: string;
  WithdrawnAt?: string;
}

export interface IProtoConsent {
  ConsentText: string;
  Partners: { name: string }[];
  Services: { name: string }[];
  CustomerID: string;
}

// We currently dont have support for adding arrays with objects through surveyJs. Create an interface for values returned from surveyJS.
export interface IFrontendConsentProps {
  ConsentText: string;
  AcceptMarketing: boolean;
  Partners: string[];
  Services: string[];
}

export type IPartialConsent = Partial<IProtoConsent>;

// if consent contains a text, and is not withdrawn it is a valid consent
export const hasConsentedToMarketing = (marketingConsent?: IConsent) =>
  marketingConsent &&
  marketingConsent.ConsentText &&
  !marketingConsent.WithdrawnAt;

export type IConsent = IProtoConsent & ICustomerBackendGeneratedAttributes;
