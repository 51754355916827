interface GAEvent {
  event: string;
  eventCategory: string;
  eventAction: string;
  eventLabel: string;
  page?: {
    title: string;
    url: `/${string}`;
  };
}

export const sendGAEvent = (event: GAEvent) => {
  const w = window as typeof window & { dataLayer: GAEvent[] };

  w.dataLayer = w.dataLayer || [];
  w.dataLayer.push(event);
};
