import { useEffect } from 'react';
import { PageModel, SurveyModel } from 'survey-core';
import useFormState from './useFormState';
import {
  updateUrlQueryParam,
  AxoUrlQueryParams,
  getUrlQueryParam,
} from '@a2755/ui-components/dist';
import { getFirstQuestionWithError } from '../survey/utils/getFirstQuestionWithError';

export const useContinueFromFirstError = (survey: SurveyModel | undefined) => {
  const [{ setupState }, dispatch] = useFormState();

  useEffect(() => {
    const currentStepFromUrl = parseInt(
      getUrlQueryParam(AxoUrlQueryParams.AXO_FORM_STEP) || '',
    );

    if (survey && setupState === 'restored') {
      const nextQuestionWithError = getFirstQuestionWithError(survey);
      if (typeof currentStepFromUrl !== 'number' || isNaN(currentStepFromUrl)) {
        if (nextQuestionWithError) {
          updateUrlQueryParam(
            (nextQuestionWithError.page as unknown as PageModel).num,
          );
          survey.currentPage = nextQuestionWithError.page;
          nextQuestionWithError.clearErrors();
        } else {
          survey.currentPageNo = survey.pageCount;
        }
      } else {
        survey.currentPageNo = currentStepFromUrl - 1;
        if (nextQuestionWithError) {
          nextQuestionWithError?.clearErrors();
        }
      }

      dispatch({ type: 'form.ready' });
    }
  }, [dispatch, setupState, survey]);
};
