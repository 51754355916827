import { SurveyModel, PageModel, Question } from 'survey-core';

export const getFieldValuesOnPage = (survey: SurveyModel, page: number) => {
  const questionsOnPage = (survey.pages[page] as PageModel)
    .questions as Question[];
  return questionsOnPage.reduce(
    (obj, question) => ({
      ...obj,
      [question.name]: question.value,
    }),
    {},
  );
};
