import { formValueValidatorsFI } from './formValueValidatorsFI';
import { formValueValidatorsNO } from './formValueValidatorsNO';
import { validSearchParams } from './validateSearchParams';

/**
 * Temporary validation until we have our own validation/state framework
 * TODO: remove when other solution is in place (edit: 1.12.2022)
 */

const validators = {
  fi: formValueValidatorsFI,
  no: formValueValidatorsNO,
};

type ValidLocale = keyof typeof validators;
const localeIsValid = (locale: unknown): locale is ValidLocale => {
  return locale === 'fi' || locale === 'no';
};

const getValidatorBySearchParam = (
  locale: ValidLocale,
  param: keyof typeof validSearchParams,
) => {
  switch (param) {
    case 'loanAmount': {
      return validators[locale].appliedAmount;
    }
    case 'loanDuration': {
      return validators[locale].loanDuration;
    }
    default:
      return undefined;
  }
};

export const validateFormValue = (
  fieldName: keyof typeof validSearchParams,
  value: number,
  locale: string | undefined,
) => {
  if (!localeIsValid(locale)) return;
  return getValidatorBySearchParam(locale, fieldName)?.(value);
};
