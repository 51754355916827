import { changeHandler } from './../../backend-sync/handle-changes/index';
import { Model } from 'survey-core';
import { ISurveyOnCurrentPageChangingOptions } from '../../types/survey/ISurveyOnCurrentPageChanging';
import { handleInitialSetup } from '../../backend-sync';
import { dispatch, getFormState } from '../../store/store';
import { getFieldValuesOnPage } from '../utils/getFieldValuesOnPage';
import { resolveWhenNoUnsyncedChanges } from '../utils/resolveWhenNoUnsyncedChanges';
import { AxoUrlQueryParams, getUrlQueryParam } from '@a2755/ui-components/dist';
import { runAsStatefulAsync } from '../../utils/statefulAsync';

export const surveyPageChanging = async (
  survey: Model,
  options: ISurveyOnCurrentPageChangingOptions,
) => {
  // Before the survey has been initialized, allow the current page to be
  // changed freely without sending calls to the backend.
  if (getFormState().setupState !== 'ready') return;

  if (options.isNextPage) {
    await handleNextPage(survey, options);
  }
};

let skipChecks = false; // Without this, calling survey.nextPage will re-trigger the same checks and cause an infinite loop
const handleNextPage = async (
  survey: Model,
  options: ISurveyOnCurrentPageChangingOptions,
) => {
  if (skipChecks) return (skipChecks = false);

  // SurveyJS does not allow async event handlers. To get around this we stop
  // the page from changing and manually call survey.nextPage when our async
  // code has completed.
  options.allowChanging = false;

  runAsStatefulAsync(async () => {
    const formState = getFormState();

    if (!formState.customer || !formState.loanApplication) {
      if (!survey) throw new Error('Missing Survey object');
      const firstPageValues = getFieldValuesOnPage(survey, 0);
      await handleInitialSetup(firstPageValues);
    } else {
      await changeHandler.onNextPage();
    }

    await resolveWhenNoUnsyncedChanges();

    const { errors, customer, loanApplication, emailVerification } =
      getFormState();
    if (emailVerification === 'cancelled') {
      dispatch({ type: 'auth.verification.required' });
      return;
    }

    if (
      errors.length === 0 &&
      customer &&
      loanApplication &&
      emailVerification === undefined
    ) {
      skipChecks = true;

      const formStepFromQuery = parseInt(
        getUrlQueryParam(AxoUrlQueryParams.AXO_FORM_STEP) || '',
      );
      if (survey.currentPageNo + 1 !== formStepFromQuery) {
        survey.nextPage();
      }
    }
  });
};
