/* eslint-disable @typescript-eslint/no-explicit-any */
import React from 'react';
import {
  ElementFactory,
  LocalizableString,
  Question,
  Serializer,
} from 'survey-core';
import {
  SurveyQuestionElementBase,
  ReactQuestionFactory,
} from 'survey-react-ui';
import styles from './CoApplicantSectionHeader.module.scss';
import { H5 } from '@a2755/ui-components';

class QuestionModel extends Question {
  questionType: string;

  constructor(questionType: string, name: string) {
    super(name);
    this.questionType = questionType;
    this.createLocalizableString('text', this, false);
  }

  getType(): string {
    const type = this.questionType ?? '';
    return type;
  }

  public get text(): string {
    return this.getLocalizableStringText('text');
  }

  public set text(val: string) {
    this.setLocalizableStringText('text', val);
  }

  get locText(): LocalizableString {
    return this.getLocalizableString('text');
  }
}

export class SurveyQuestion extends SurveyQuestionElementBase {
  get question() {
    return this.questionBase as QuestionModel;
  }

  protected renderElement(): JSX.Element {
    return <H5 className={styles.root}>{this.question.text}</H5>;
  }
}

export function registerCoApplicantSectionHeaderQuestion() {
  const questionType = 'Axo CoApplicant Section Header';

  // this serializes the class into JSON
  Serializer.addClass(
    questionType,

    // Add properties that should be accessed in Creator.
    [
      {
        name: 'text',
        category: 'general',
        type: 'string',
        serializationProperty: 'locText',
      },
    ],
    () => new QuestionModel(questionType, ''),
    'question',
  );

  ReactQuestionFactory.Instance.registerQuestion(questionType, (props: any) =>
    React.createElement(SurveyQuestion, props),
  );

  ElementFactory.Instance.registerElement(questionType, (name: string) => {
    return new QuestionModel(questionType, name);
  });
}
