import React from 'react';
import './index.scss';
import App from './App';
import { BrowserRouter } from 'react-router-dom';
import '@a2755/ui-components/dist/index.css';
import packageJson from '../package.json';
import { createRoot } from 'react-dom/client';

if (['development', 'staging'].includes(process.env.NODE_ENV)) {
  console.log(
    'UI-Components version: ' +
      packageJson['dependencies']['@a2755/ui-components'],
  );
}

const id = 'axo-root';
const element = document.getElementById(id);

if (!element)
  throw new Error(`Unable to mount react: no element with id "${id}"`);

const root = createRoot(element);

root.render(
  <React.StrictMode>
    <BrowserRouter>
      <App />
    </BrowserRouter>
  </React.StrictMode>,
);
