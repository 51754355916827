import { Question, SurveyModel } from 'survey-core';
import { runAsStatefulAsync } from './../../utils/statefulAsync';
import { getStepName } from '../../utils/getStepName';
import { ISurveyOnCompleting } from '../../types/survey/ISurveyOnCompleting';
import { completeApplication } from '../../backend-sync/completeApplication';
import { completeStep } from '../../backend-sync/completeStep';
import { getFormState } from '../../store';
import { clearReloadData } from '../../hooks/useReloadFromBackendOnInit';
import { changeHandler } from '../../backend-sync';
import { IChanges } from '../../types/data/IChanges';
import { getFirstQuestionWithError } from '../utils/getFirstQuestionWithError';
import { captureMessage } from '@sentry/react';
import { addStepCompleteBreadcrumb } from '../../utils/monitoring/addStepCompleteBreadcrumb';
import reloadState from '../../backend-sync/reloadState';
import injectData from '../injectData';
import runWithManualRetry from '../../utils/runWithManualRetry';
import Cookie from '../../utils/Cookie';

const isEnvProduction = process.env.REACT_APP_ENV === 'production';
const trackingCookieName = 'track_application_complete';

export const surveyCompleting = async (
  survey: SurveyModel,
  options: ISurveyOnCompleting,
) => {
  options.allowComplete = false;

  const nextQuestionWithError = getFirstQuestionWithError(survey);
  if (nextQuestionWithError) {
    survey.currentPage = nextQuestionWithError.page;
    return;
  }

  registerNullChangesToHiddenFields(survey);

  await changeHandler.onSubmit();

  await runAsStatefulAsync(async () => {
    if (await finalBackendSync(survey)) {
      const finalPageInSurvey = survey.pages[survey.pages.length - 1];
      await completeStep(getStepName(finalPageInSurvey));
      await completeApplication(survey.data);

      addStepCompleteBreadcrumb({
        message: `Final step completed`,
        data: survey.data,
      });
      captureMessage('Application completed');

      const { loanApplication, customer } = getFormState();
      clearReloadData();

      if (!loanApplication?.ID || !customer?.JWT)
        throw new Error('Missing application ID or JWT in formstate');

      redirect({
        locale: survey.locale,
        applicationID: loanApplication?.ID,
        token: customer?.JWT,
      });
    }
  });

  storeTrackingDataInCookie(survey);
};

const redirect = ({
  locale,
  applicationID,
  token,
}: {
  locale: string;
  applicationID: string;
  token: string;
}) => {
  let redirectUrl = getSuccessPageOrDefault(locale);
  // If it is the finnish config we redirect with context url parameters (token and applicationID) for the insuranceform
  if (locale === 'fi') {
    redirectUrl = addContextDataToUrl({
      applicationID: applicationID,
      token: token,
      url: redirectUrl,
    });
  }
  window.location.href = redirectUrl;
};

const storeTrackingDataInCookie = async (survey: SurveyModel) => {
  const { loanApplication, customer } = getFormState();
  if (!loanApplication || !customer)
    throw new Error(
      'Expected loan application and customer to be defined in form state',
    );

  const domainName = window.location.host.match(/\w+\.\w+$/)?.[0];
  const data = JSON.stringify({
    customerID: customer.ID,
    loanApplicationID: loanApplication.ID,
    formCompletedTimestamp: new Date().toISOString(),
    appliedAmount: survey.getQuestionByName('LoanApplication/AppliedAmount')
      .value,
    didApplyForRefinancing:
      survey.getQuestionByName('LoanApplication/Purpose').value ===
      'Refinancing loan',
  });

  Cookie.set(trackingCookieName, JSON.stringify(data), {
    path: '/',
    domain: '.' + domainName,
  });
};

const registerNullChangesToHiddenFields = (survey: SurveyModel) => {
  const questions = survey.getAllQuestions();
  let notVisibleFields: IChanges = {};
  questions.forEach((q) => {
    if (questionShouldBeNulled(q, survey)) {
      notVisibleFields = { ...notVisibleFields, [q.name]: null };
    }
  });
  if (Object.keys(notVisibleFields).length) {
    changeHandler.registerChange(notVisibleFields);
  }
};

const questionShouldBeNulled = (question: Question, survey: SurveyModel) => {
  const questions = survey.getAllQuestions();
  const questionsByName = questions.filter((q) => q.name === question.name);
  // if one field has multiple question instances, we do not want to null this if only one of them is hidden.
  return questionsByName
    .map(
      (q) =>
        (!q.isVisible || !q.isParentVisible) && (q.value || q.defaultValue),
    )
    .reduce((sum, next) => sum && next);
};

const finalBackendSync = async (survey: SurveyModel): Promise<boolean> => {
  let success = true;
  await runWithManualRetry(async () => {
    if (!(await backendIsInvalid(survey))) return;

    captureMessage(
      'Backend validation failed (will try to fix this by syncing with the backend).',
    );

    await changeHandler.registerChange(survey.data);
    await changeHandler.onSubmit();

    if (await backendIsInvalid(survey)) {
      success = false;
      throw new Error(
        'Failed to validate backend data based on form rules and was not able to fix it by syncing.',
      );
    }
  });
  return success;
};

const backendIsInvalid = async (survey: SurveyModel): Promise<boolean> => {
  const frontendData = survey.data;

  const { loanApplication, customer } = getFormState();
  if (!loanApplication || !customer)
    throw new Error(
      'Expected loan application and customer to be defined in form state',
    );

  const backendData = await reloadState({
    loanApplicationId: loanApplication.ID,
    JWT: customer.JWT,
  });
  survey.clear(true, false);
  injectData(survey, backendData);

  const questionWithError = getFirstQuestionWithError(survey);

  survey.data = frontendData;

  return !!questionWithError;
};

/**
 * Until we have a url on the same domain use the full urls and not just the route.
 * We should not redirect to production thankyoupage when not in production environment - it will clutter tracking.
 **/
const getDefaultSuccessPageByLocale = (locale: string) => {
  switch (locale) {
    case 'no': {
      return isEnvProduction
        ? 'https://www2.axofinans.no/s%C3%B8k/forbruksl%C3%A5n/takk?form=new'
        : 'https://www.axofinans.no/staging/thank-you-page?form=new';
    }
    case 'fi': {
      return isEnvProduction
        ? 'https://vakuutus.axolaina.fi?form=new'
        : 'https://insurance.fi.axo.ninja?form=new';
    }
    default:
      return '';
  }
};

const addContextDataToUrl = ({
  url,
  applicationID,
  token,
}: {
  url: string;
  applicationID: string;
  token: string;
}): string => {
  const u = new URL(url);
  u.hash = `token=${token}`;
  u.searchParams.append('id', applicationID);
  return u.toString();
};

export const getSuccessPageOrDefault = (locale: string) => {
  const path =
    document.querySelector('#axo-root')?.getAttribute('data-success-page') ||
    getDefaultSuccessPageByLocale(locale);
  return path;
};
