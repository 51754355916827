import { questionSurveyRegisters } from '@a2755/ui-components/dist/questions';
import { registerAggregateValueQuestion } from './AggregateValue.question';
import { registerCoApplicantSectionHeaderQuestion } from './CoApplicantSectionHeader/CoApplicantSectionHeading.question';
import { registerHorizontalSeparatorQuestion } from './HorizontalSeparator/HorizontalSeparator.question';

export const registerQuestions = () => {
  Object.values(questionSurveyRegisters).forEach((register) => register());
  registerAggregateValueQuestion();
  registerCoApplicantSectionHeaderQuestion();
  registerHorizontalSeparatorQuestion();
};
