/**
 * Takes a function and returns a new debounced function, meaning that if the
 * function is called multiple times in a row, before a certain delay has been
 * reached, only the most recent call to the function will be executed. Note
 * that it is the function returned that has the debounce functionality, so we need to make sure
 * the function is not recreated every time it is used. This means that the
 * function should be used together with useMemo or similar if used in a component. Since
 * the execution of the function will be after the delay, it returns a Promise
 * which resolves to the return value of the original pre-debounced function
 * (the return value of the last call).
 */
export const debounceFunction = <Args extends unknown[], T, R = Awaited<T>>(
  callback: (...args: Args) => T,
  debounceForMs = 500,
): ((...args: Args) => Promise<R>) => {
  let timeout: NodeJS.Timeout;
  const fn = callback;

  return async (...args: Args) =>
    new Promise((resolve) => {
      if (timeout) clearTimeout(timeout);

      const currentTimeout = setTimeout(() => {
        if (currentTimeout === timeout)
          resolve(fn(...args) as unknown as Promise<R>);
      }, debounceForMs);

      timeout = currentTimeout;
    });
};
