import { IPartialPerson, IProtoPerson } from './../../types/data/IPerson';
import {
  IPersistanceValues,
  PersistanceValuesSchema,
} from '../../types/IPersistanceValues';
import { IAuthorizationToken } from './../../types/data/IAuthorizationToken';
import { IDebt, IPartialDebt, IProtoDebt } from './../../types/data/IDebt';
import {
  IPartialLoanApplication,
  IProtoLoanApplication,
  ILoanApplication,
} from './../../types/data/ILoanApplication';
import {
  ICustomer,
  ICustomerWithoutJWT,
  IPartialCustomer,
  IProtoCustomer,
} from './../../types/data/ICustomer';
import {
  Client,
  IClientApplicationIdOption,
  IClientAuthorizationHeaderOption,
} from './Client';
import { IChannelSnapshot } from '../../types/data/IChannelSnapshot';
import { IPerson } from '../../types/data/IPerson';
import { IConsent, IProtoConsent } from '../../types/data/IConsent';
import { IChild } from '../../types/data/IChild';
import { dispatch, getFormState } from '../../store';

class HttpClient extends Client {
  private readonly BASE_URL = process.env.REACT_APP_API_URL;

  private getCustomerUrl(customerId: string | null) {
    const suffix = customerId !== null ? `/${customerId}` : '';
    return `${this.BASE_URL}/customer${suffix}`;
  }

  private getSendPinUrl() {
    return `${this.BASE_URL}/auth/pin`;
  }

  private getPinVerificationUrl(customerId: string) {
    return `${this.BASE_URL}/auth/pin/customer/${customerId}`;
  }

  private getPersonUrl(personID: string | null) {
    const suffix = personID !== null ? `/${personID}` : '';
    return `${this.BASE_URL}/person${suffix}`;
  }

  private getConsentUrl(id: string | null) {
    const suffix = id !== null ? `/${id}` : '';
    return `${this.BASE_URL}/marketing-consent${suffix}`;
  }

  private getRevokeConsentUrl() {
    return `${this.BASE_URL}/marketing-consent/revert`;
  }

  private getLoanApplicationUrl(applicationId: string | null) {
    const suffix = applicationId !== null ? `/${applicationId}` : '';
    return `${this.BASE_URL}/loan-application${suffix}`;
  }

  private getMagicLinkURL(magicTokenUUID: string) {
    return `${this.BASE_URL}/loan-application/magic/${magicTokenUUID}`;
  }

  private getDebtUrl(loanApplicationId: string) {
    return `${this.getLoanApplicationUrl(loanApplicationId)}/debt`;
  }
  private getChildUrl(loanApplicationId: string) {
    return `${this.getLoanApplicationUrl(loanApplicationId)}/child`;
  }

  private getStepCompleteUrl(loanApplicationId: string) {
    return `${this.getLoanApplicationUrl(loanApplicationId)}/step-complete`;
  }

  private getApplicationCompleteUrl(loanApplicationId: string) {
    return `${this.getLoanApplicationUrl(
      loanApplicationId,
    )}/application-complete`;
  }

  private getApplicationChannelSnapshotUrl(loanApplicationId: string) {
    return `${this.getLoanApplicationUrl(loanApplicationId)}/channel-snapshot`;
  }

  override stepCompleted(
    stepName: string,
    {
      LoanApplicationID,
      Authorization,
    }: IClientApplicationIdOption & IClientAuthorizationHeaderOption,
  ): Promise<void> {
    return fetch(this.getStepCompleteUrl(LoanApplicationID), {
      method: 'PATCH',
      headers: {
        Authorization: Authorization,
      },
      body: JSON.stringify({
        Step: stepName,
      }),
    }).then(() => undefined);
  }

  override applicationCompleted(
    finalState: unknown, // The finalState is only added so that it will show up in the backend logs
    {
      LoanApplicationID,
      Authorization,
    }: IClientApplicationIdOption & IClientAuthorizationHeaderOption,
  ): Promise<void> {
    return fetch(this.getApplicationCompleteUrl(LoanApplicationID), {
      method: 'PATCH',
      headers: {
        Authorization: Authorization,
      },
      body: JSON.stringify(finalState),
    }).then(() => undefined);
  }

  addChannelSnapshot(
    data: IChannelSnapshot,
    {
      LoanApplicationID,
      Authorization,
    }: IClientApplicationIdOption & IClientAuthorizationHeaderOption,
  ): Promise<void> {
    const URL = this.getApplicationChannelSnapshotUrl(LoanApplicationID);
    return this.fetch(URL, {
      method: 'POST',
      body: JSON.stringify(data),
      headers: {
        Authorization: Authorization,
      },
    }).then(() => undefined);
  }

  override createMarketingConsent(
    data: IProtoConsent,
    headers: IClientAuthorizationHeaderOption,
  ) {
    const URL = this.getConsentUrl(null);
    return this.fetch(URL, {
      method: 'POST',
      body: JSON.stringify(data),
      headers: {
        Authorization: headers.Authorization,
      },
    }).then((response) => response.json());
  }

  override revokeMarketingConsent(
    customerId: string,
    headers: IClientAuthorizationHeaderOption,
  ): Promise<void> {
    const URL = this.getRevokeConsentUrl();
    return this.fetch(URL, {
      method: 'POST',
      headers: {
        Authorization: headers.Authorization,
      },
      body: JSON.stringify({
        CustomerID: customerId,
      }),
    }).then((response) => response.json());
  }

  override getMarketingConsent(
    marketingConsentId: string,
    headers: IClientAuthorizationHeaderOption,
  ): Promise<IConsent> {
    const URL = this.getConsentUrl(marketingConsentId);
    return this.fetch(URL, {
      method: 'GET',
      headers: {
        Authorization: headers.Authorization,
      },
    }).then((response) => response.json());
  }

  override createCustomer(options: IProtoCustomer): Promise<ICustomer> {
    const URL = this.getCustomerUrl(null);
    return this.fetch(URL, {
      method: 'POST',
      body: JSON.stringify(options),
    }).then((response) => response.json());
  }

  override updateCustomer(
    id: string,
    data: IPartialCustomer,
    options: IClientAuthorizationHeaderOption,
  ): Promise<ICustomerWithoutJWT> {
    const URL = this.getCustomerUrl(id);
    return this.fetch(URL, {
      method: 'PATCH',
      headers: {
        Authorization: options.Authorization,
      },
      body: JSON.stringify(data),
    }).then((response) => response.json());
  }

  override getCustomer(
    id: string,
    headers: IClientAuthorizationHeaderOption,
  ): Promise<ICustomerWithoutJWT> {
    const URL = this.getCustomerUrl(id);
    return this.fetch(URL, {
      headers: {
        Authorization: headers.Authorization,
      },
    }).then((response) => response.json());
  }

  override async sendEmailVerificationPin(customerId: string): Promise<void> {
    const URL = this.getSendPinUrl();
    await this.fetch(URL, {
      method: 'POST',
      body: JSON.stringify({
        CustomerID: customerId,
      }),
    });
  }

  override async getVerifiedJWT(
    customerId: string,
    pin: string,
  ): Promise<IAuthorizationToken> {
    const URL = this.getPinVerificationUrl(customerId);
    return await this.fetch(URL, {
      method: 'POST',
      body: JSON.stringify({
        Pin: pin,
      }),
    }).then((response) => response.json());
  }

  override createPerson(
    data: IProtoPerson,
    headers: IClientAuthorizationHeaderOption,
  ): Promise<IPerson> {
    const URL = this.getPersonUrl(null);
    return this.fetch(URL, {
      method: 'POST',
      headers: {
        Authorization: headers.Authorization,
      },
      body: JSON.stringify(data),
    }).then((response) => response.json());
  }

  override updatePerson(
    id: string,
    data: IPartialPerson,
    headers: IClientAuthorizationHeaderOption,
  ): Promise<IPerson> {
    const URL = this.getPersonUrl(id);
    return this.fetch(URL, {
      method: 'PATCH',
      headers: {
        Authorization: headers.Authorization,
      },
      body: JSON.stringify(data),
    }).then((response) => response.json());
  }

  createLoanApplication(
    data: IProtoLoanApplication,
    headers: IClientAuthorizationHeaderOption,
  ): Promise<ILoanApplication> {
    const URL = this.getLoanApplicationUrl(null);
    return this.fetch(URL, {
      method: 'POST',
      headers: {
        ...headers,
      },
      body: JSON.stringify(data),
    }).then((response) => response.json());
  }

  override updateLoanApplication(
    id: string,
    data: IPartialLoanApplication,
    options: IClientAuthorizationHeaderOption,
  ): Promise<void> {
    const URL = this.getLoanApplicationUrl(id);
    return this.fetch(URL, {
      method: 'PATCH',
      headers: {
        Authorization: options.Authorization,
      },
      body: JSON.stringify(data),
    }).then((response) => response.json());
  }

  override getLoanApplication(
    loanApplicationId: string,
    headers: IClientAuthorizationHeaderOption,
  ): Promise<ILoanApplication> {
    const URL = this.getLoanApplicationUrl(loanApplicationId);
    return this.fetch(URL, {
      headers: {
        Authorization: headers.Authorization,
      },
    }).then((response) => response.json());
  }

  override getPersistanceValues(
    magicTokenUUID: string,
  ): Promise<IPersistanceValues> {
    const URL = this.getMagicLinkURL(magicTokenUUID);
    return this.fetch(URL)
      .then((response) => response.json())
      .then((data: ILoanApplication & { JWT: IAuthorizationToken }) => {
        const reloadData: IPersistanceValues = {
          JWT: data.JWT,
          loanApplicationId: data.ID,
        };

        return PersistanceValuesSchema.parse(reloadData);
      });
  }

  override getPerson(
    personID: string,
    headers: IClientAuthorizationHeaderOption,
  ): Promise<IPerson> {
    const URL = this.getPersonUrl(personID);

    return this.fetch(URL, {
      method: 'GET',
      headers: {
        Authorization: headers.Authorization,
      },
    }).then((response) => response.json());
  }

  protected override updateDebtObject(
    id: string,
    data: IPartialDebt,
    options: IClientAuthorizationHeaderOption & IClientApplicationIdOption,
  ): Promise<void> {
    const URL = this.getDebtUrl(options.LoanApplicationID);
    return this.fetch(URL, {
      method: 'PATCH',
      headers: {
        Authorization: options.Authorization,
      },
      body: JSON.stringify({
        ...data,
        ID: id,
      }),
    })
      .then((response) => response.json())
      .then((json) => json.ID);
  }

  protected async createDebtObject(
    data: IProtoDebt,
    options: IClientAuthorizationHeaderOption & IClientApplicationIdOption,
  ): Promise<string> {
    const URL = this.getDebtUrl(options.LoanApplicationID);
    return this.fetch(URL, {
      method: 'POST',
      headers: {
        Authorization: options.Authorization,
      },
      body: JSON.stringify(data),
    })
      .then((response) => response.json())
      .then((json) => json.ID);
  }

  getAllDebtObjects(
    loanApplicationId: string,
    headers: IClientAuthorizationHeaderOption,
  ): Promise<IDebt[]> {
    const URL = this.getDebtUrl(loanApplicationId);
    return this.fetch(URL, {
      headers: {
        Authorization: headers.Authorization,
      },
    }).then((response) => response.json());
  }

  private get childrenFieldNameAndIds() {
    const { fieldNameToBackendIDMap } = getFormState();
    return Object.entries(fieldNameToBackendIDMap)
      .filter(([fieldName]) => fieldName.startsWith('Child/'))
      .map(([fieldName, backendID]) => ({ fieldName, backendID }));
  }

  override async getChildren(
    options: IClientAuthorizationHeaderOption & IClientApplicationIdOption,
  ): Promise<IChild[]> {
    const URL = this.getChildUrl(options.LoanApplicationID);
    return await fetch(URL, {
      method: 'GET',
      headers: {
        Authorization: options.Authorization,
      },
    }).then((response) => response.json());
  }

  protected override async updateAmountOfChildren(
    amount: number,
    options: IClientAuthorizationHeaderOption & IClientApplicationIdOption,
  ): Promise<void> {
    if (this.childrenFieldNameAndIds.length < amount) {
      await this.increaseAmountOfChildren(amount, options);
    } else if (this.childrenFieldNameAndIds.length > amount) {
      await this.reduceAmountOfChildren(amount, options);
    }
  }

  private async increaseAmountOfChildren(
    amount: number,
    options: IClientAuthorizationHeaderOption & IClientApplicationIdOption,
  ) {
    for (let i = this.childrenFieldNameAndIds.length; i < amount; i++) {
      await fetch(this.getChildUrl(options.LoanApplicationID), {
        method: 'POST',
        headers: {
          Authorization: options.Authorization,
        },
        body: JSON.stringify({}),
      })
        .then((response) => response.json() as Promise<IChild>)
        .then((child) =>
          dispatch({
            type: 'field-name-to-backend-id-map.add',
            backendID: child.ID,
            fieldName: `Child/${child.ID}`,
          }),
        );
    }
  }

  private async reduceAmountOfChildren(
    amount: number,
    options: IClientAuthorizationHeaderOption & IClientApplicationIdOption,
  ) {
    for (let i = this.childrenFieldNameAndIds.length - 1; i >= amount; i--) {
      const { fieldName, backendID } = this.childrenFieldNameAndIds[i];
      await fetch(this.getChildUrl(options.LoanApplicationID), {
        method: 'DELETE',
        headers: {
          Authorization: options.Authorization,
        },
        body: JSON.stringify({
          ID: backendID,
        }),
      }).then(() =>
        dispatch({ type: 'field-name-to-backend-id-map.remove', fieldName }),
      );
    }
  }
}

export default HttpClient;
