import { SurveyModel } from 'survey-core';
import { validateFormValue } from './formValueValidators/validateFormValue';
import {
  searchParamIsValid,
  validSearchParams,
} from './formValueValidators/validateSearchParams';

export const setFormValuesFromQueryParams = (
  survey: SurveyModel | undefined,
) => {
  const url = new URL(window.location.href);
  const searchParams = url.searchParams;

  searchParams.forEach((val, key) => {
    if (searchParamIsValid(key)) {
      const validationRes = validateFormValue(
        key,
        parseInt(val, 10),
        survey?.locale,
      );

      if (validationRes) {
        setValueInSurveyJs(validSearchParams[key], validationRes, survey);
      }
    }
  });
};

const setValueInSurveyJs = (
  questionName: string,
  value: number,
  survey: SurveyModel | undefined,
) => {
  const question = survey?.getQuestionByName(questionName);
  if (!question || !survey) return;
  question.setPropertyValue('value', value);
  // need to set undefined first in order for surveyjs to understand that there is a change.
  question.value = undefined;
  question.value = value;
};
