import { IChannelSnapshot } from './../types/data/IChannelSnapshot';

enum ClickIDParamName {
  GOOGLE = 'gclid',
  GOOGLE_ATT_APP = 'gbraid', // ATT: App Tracking Transparency - a new Apple policy
  GOOGLE_ATT_WEB = 'wbraid',
  FACEBOOK = 'fbclid',
  BING = 'msclkid',
}

const documentReferrerToCampaignSourceMap: { regex: RegExp; name: string }[] = [
  { regex: /google.[a-z]{2,3}(#|\?|\/|$)/, name: 'Google Search' },
  { regex: /bing.[a-z]{2,3}(#|\?|\/|$)/, name: 'Bing Search' },
];

export const getChannelSnapshot = (
  searchParams: URLSearchParams,
  completedSteps: string[],
): IChannelSnapshot => {
  const startedAtUrl = getStartedAtUrl();

  return {
    CampaignSource: searchParams.get('utm_source') || undefined,
    CampaignMedium: searchParams.get('utm_medium') || undefined,
    CampaignTerm: searchParams.get('utm_term') || undefined,
    CampaignContent: searchParams.get('utm_content') || undefined,
    CampaignName: searchParams.get('utm_campaign') || undefined,
    UserAgent: navigator.userAgent,
    GoogleClickID: searchParams.get(ClickIDParamName.GOOGLE) || undefined,
    // ClientID:
    HasOffersTransactionID: searchParams.get('transaction_id') || undefined,
    DocumentReferrer: document.referrer,
    Keyword: searchParams.get('keyword') || undefined,
    FacebookClientID: searchParams.get(ClickIDParamName.FACEBOOK) || undefined,
    // FacebookPixelID:
    // WhitelabelPartnerClickID:
    Action: completedSteps[completedSteps.length - 1],
    StartedAtUrl: startedAtUrl,

    ...getChannelSnapshotMappingOverwrites(searchParams),
  };
};

const getGoogleClickId = (sp: URLSearchParams) =>
  sp.get(ClickIDParamName.GOOGLE) ??
  sp.get(ClickIDParamName.GOOGLE_ATT_APP) ??
  sp.get(ClickIDParamName.GOOGLE_ATT_WEB);

const getChannelSnapshotMappingOverwrites = (
  sp: URLSearchParams,
): Partial<IChannelSnapshot> => {
  const googleClickId = getGoogleClickId(sp);
  if (googleClickId)
    return {
      CampaignSource: 'Adwords',
      CampaignContent: googleClickId || undefined,
    };

  if (sp.get(ClickIDParamName.BING))
    return {
      CampaignSource: 'bing',
      CampaignContent: sp.get(ClickIDParamName.BING) || undefined,
    };

  if (document.referrer === '')
    return {
      DocumentReferrer: 'Direct',
    };

  for (const r of documentReferrerToCampaignSourceMap) {
    if (r.regex.test(document.referrer)) {
      return {
        CampaignSource: r.name,
      };
    }
  }

  return {};
};

const getStartedAtUrl = () => {
  let StartedAtUrl = sessionStorage.getItem('StartedAtUrl');
  if (!StartedAtUrl) {
    StartedAtUrl = window.location.pathname;
    sessionStorage.setItem('StartedAtUrl', StartedAtUrl);
  }
  return StartedAtUrl;
};
