import { ErrorModel, PinAlert } from '@a2755/ui-components';
import { FC, useEffect, useState } from 'react';
import client from '../backend-sync/client';
import useFormState from '../hooks/useFormState';
import { dispatch } from '../store';
import { sendGAEvent } from '../utils/sendGAEvent';
import { HttpError } from '../utils/customFetch';

interface IEmailVerificationModal {
  email: string;
  onVerify: () => void;
}

export const EmailVerificationModal: FC<IEmailVerificationModal> = ({
  email,
  onVerify,
}) => {
  const [{ customer }] = useFormState();
  const [pin, setPin] = useState<string>('');
  const [hasSentPin, setHasSentPin] = useState<boolean>(false);
  const [errors, setErrors] = useState<ErrorModel[]>([]);

  useEffect(() => {
    sendGAEvent({
      event: 'form-verification',
      eventCategory: 'Form',
      eventAction: 'Form Verification Viewed',
      eventLabel: '',
    });
  }, []);

  if (hasSentPin) {
    return (
      <PinAlert
        errors={errors}
        title="Tarkista sähköpostisi"
        mainText={`Lähetimme sinulle vahvistuskoodin osoitteeseen: ${email}<br /><br /> Syötä saamasi koodi tähän:`}
        secondaryText=""
        cancelButtonText="Peruuta"
        okButtonText="Vahvista"
        pinTextLeft="Etkö saanut sähköpostia?"
        pinTextRight="Lähetä uudelleen"
        showPinCodeInput
        onChange={setPin}
        onCancelButtonClick={() => {
          dispatch({ type: 'auth.verification.canceled' });
        }}
        onOkButtonClick={async () => {
          if (!customer) throw new Error('Missing customer in Pin Alert');
          try {
            const JWT = await client.getVerifiedJWT(customer.ID, pin);

            dispatch({
              type: 'customer.replaced',
              customer: { ...customer, JWT },
            });

            dispatch({ type: 'auth.verification.obtained' });

            onVerify();
          } catch (err) {
            if (err instanceof HttpError) {
              setErrors([
                {
                  code: err.statusCode.toString(),
                  message:
                    err.statusCode === 401
                      ? 'Invalid pin code, please try again.'
                      : 'Unexpected error, please try again or contact support if the issue persists.',
                },
              ]);
            } else if (err instanceof Error)
              setErrors([{ message: err.message }]);
          }
        }}
        onClickPinTextRight={async () => {
          if (!customer) throw new Error('Missing customer in Pin Alert');
          await client.sendEmailVerificationPin(customer.ID);
        }}
      />
    );
  }

  return (
    <PinAlert
      title="Tervetuloa takaisin!"
      mainText="Olet aiemmin tehnyt hakemuksen Axolainalle eri yhteystiedoilla. Jatkaaksesi sinun täytyy <strong>vahvistaa sähköpostisi.</strong>"
      secondaryText="Lähetä vahvistusviesti alla olevasta painikkeesta ja laita sähköpostiisi lähetetty vahvistuskoodi sille varattuun kenttään."
      cancelButtonText="Peruuta"
      okButtonText="Lähetä vahvistusviesti"
      onCancelButtonClick={() => {
        dispatch({ type: 'auth.verification.canceled' });
      }}
      onOkButtonClick={async () => {
        if (!customer) throw new Error('Missing customer in Pin Alert');
        await client.sendEmailVerificationPin(customer.ID);
        setHasSentPin(true);
      }}
    />
  );
};
