import { addBreadcrumb as sentryAddBreadcrumb } from '@sentry/react';
import { scrubObjectProperties } from '../scrubObjectProperties';

interface IBreadcrumb {
  message: string;
  data: Record<string, unknown>;
}
export const addStepCompleteBreadcrumb = (breadcrumb: IBreadcrumb) =>
  sentryAddBreadcrumb({
    category: 'step-complete',
    message: breadcrumb.message,
    timestamp: Date.now(),
    data: scrubObjectProperties(breadcrumb.data),
  });
