import { ILocalizationConfig } from '../type';

export const fi: ILocalizationConfig = {
  genericErrorAlert: {
    title: 'Something went wrong when reaching our servers!',
    paragraphs: [
      'Please check your connection and try again.',
      'Call Axo Laina on <a href="tel:0942452326">09 4245 2326</a> if you require further assistance.',
    ],
    retry: 'Retry',
  },
};
