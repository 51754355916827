export type Observer<T> = (value: T, previousValue: T | undefined) => void;

/**
 * Allows subscribing to changes in an arbitrary value
 */
class Subject<T> {
  private observers: Observer<T>[] = [];

  constructor(private _value: T) {}

  subscribe(observer: Observer<T>) {
    this.observers.push(observer);
    observer(this._value, undefined);
  }

  unsubscribe(observer: Observer<T>) {
    this.observers = this.observers.filter((o) => o !== observer);
  }

  set value(newValue: T) {
    const previousValue = this._value;
    this._value = newValue;
    this.observers.forEach((observer) => observer(this._value, previousValue));
  }

  get value() {
    return this._value;
  }
}

export default Subject;
