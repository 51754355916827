import reducer from './reducer';
import Subject from '../utils/Subject';
import { IFormState } from './state';
import { IFormStateAction } from './actions';

export const initialState: IFormState = {
  loadingState: 'idle',
  errors: [],
  hasUnsyncedChanges: false,
  completedSteps: [],
  setupState: 'idle',
  channelSnapshot: {},
  fieldNameToBackendIDMap: {},
};

const store = new Subject<IFormState>(initialState);

export const dispatch = (action: IFormStateAction) =>
  (store.value = reducer(store.value, action));

export const subscribe = store.subscribe.bind(store);
export const unsubscribe = store.unsubscribe.bind(store);
export const getFormState = () => store.value;
