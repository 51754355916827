import { updatePerson } from './update-person/updatePerson';
import { IPersistanceValues } from '../../types/IPersistanceValues';
import { IChannelSnapshot } from './../../types/data/IChannelSnapshot';
import { IDebt, IPartialDebt, IProtoDebt } from './../../types/data/IDebt';
import { IProtoLoanApplication } from './../../types/data/ILoanApplication';
import {
  IPartialCustomer,
  IProtoCustomer,
  ICustomerWithoutJWT,
} from './../../types/data/ICustomer';
import { dispatch, getFormState } from '../../store';
import { IAuthorizationToken } from '../../types/data/IAuthorizationToken';
import { IChanges } from '../../types/data/IChanges';
import { ICustomer } from '../../types/data/ICustomer';
import {
  ILoanApplication,
  IPartialLoanApplication,
} from '../../types/data/ILoanApplication';
import { handleChangesPerTypeFactory } from './change-router';
import { IPerson, IProtoPerson } from '../../types/data/IPerson';
import { IConsent } from '../../types/data/IConsent';
import { IChild } from '../../types/data/IChild';
import { updateCustomer } from './update-customer';

export interface IClientAuthorizationHeaderOption {
  Authorization: IAuthorizationToken;
}
export interface IClientCustomerIdOption {
  CustomerID: string;
}
export interface IClientApplicationIdOption {
  LoanApplicationID: string;
}

export abstract class Client {
  constructor(protected fetch: typeof window.fetch) {}

  abstract stepCompleted(
    stepName: string,
    {
      LoanApplicationID,
      Authorization,
    }: IClientApplicationIdOption & IClientAuthorizationHeaderOption,
  ): Promise<void>;

  abstract applicationCompleted(
    finalState: unknown,
    {
      LoanApplicationID,
      Authorization,
    }: IClientApplicationIdOption & IClientAuthorizationHeaderOption,
  ): Promise<void>;

  abstract addChannelSnapshot(
    data: IChannelSnapshot,
    {
      LoanApplicationID,
      Authorization,
    }: IClientApplicationIdOption & IClientAuthorizationHeaderOption,
  ): Promise<void>;

  // Changes to Person (SSN) should always create a new Person
  // and update LoanApplication with PersonID.
  public async updateFields(
    data: IChanges,
    options: IClientAuthorizationHeaderOption &
      IClientCustomerIdOption &
      IClientApplicationIdOption,
  ): Promise<void> {
    const changeHandler = handleChangesPerTypeFactory({
      Customer: async (_, changes) => {
        await updateCustomer(
          {
            ...changes,
            MobilePhoneNumber: changes.MobilePhoneNumber?.toString(),
          },
          {
            JWT: options.Authorization,
          },
        );
      },

      CoCustomer: async (_, changes) => {
        if (!changes.Email) {
          console.warn('missing email for CoCustomer');
          return;
        }
        if (!changes.MobilePhoneNumber) {
          console.warn('missing MobilePhoneNumber for CoCustomer');
          return;
        }

        if (!changes.MobilePhoneCountryCode) {
          console.warn('missing mobilePhoneCountryCode for CoCustomer');
          return;
        }

        const { ID: coCustomerID, JWT: coJWT } = await this.createCustomer({
          Email: changes.Email,
        });

        await this.updateCustomer(
          coCustomerID,
          {
            ...changes,
          },
          {
            Authorization: coJWT,
          },
        );

        return this.updateLoanApplication(
          options.LoanApplicationID,
          {
            CoApplicantCustomerID: coCustomerID,
          },
          {
            Authorization: options.Authorization,
          },
        );
      },

      Consent: async (_, changes) => {
        const { AcceptMarketing, ConsentText, Partners, Services } = changes;
        if (!AcceptMarketing) {
          const id =
            getFormState().fieldNameToBackendIDMap['Static/MarketingConsent'];
          if (!id) return;

          const { customer } = getFormState();
          if (!customer) {
            throw new Error('Could not create consent: missing customer');
          }

          await this.revokeMarketingConsent(customer.ID, {
            Authorization: options.Authorization,
          });
          await this.updateLoanApplication(
            options.LoanApplicationID,
            {
              MarketingConsentID: null,
            },
            {
              Authorization: options.Authorization,
            },
          );

          // The id is used to know whether we have created a consent in this
          // session. If we don't remove it here, it is possible to revert older
          // consents by quickly toggling the checkbox on and off again.
          dispatch({
            type: 'field-name-to-backend-id-map.remove',
            fieldName: `Static/MarketingConsent`,
          });
        } else {
          const { customer } = getFormState();
          if (!customer) {
            throw new Error('Could not create consent: missing customer');
          }
          if (!Partners) {
            throw new Error('Could not create consent: missing partners');
          }

          const partnersAsBackendModel = Partners.map((partner) => ({
            name: partner,
          }));

          if (!Services) {
            throw new Error('Could not create consent: missing services');
          }

          const servicesAsBackendModel = Services.map((service) => ({
            name: service,
          }));

          const marketingConsent = await this.createMarketingConsent(
            {
              ConsentText,
              CustomerID: customer.ID,
              Partners: partnersAsBackendModel,
              Services: servicesAsBackendModel,
            },
            {
              Authorization: customer.JWT,
            },
          );

          dispatch({
            type: 'field-name-to-backend-id-map.add',
            backendID: marketingConsent.ID,
            fieldName: `Static/MarketingConsent`,
          });

          await this.updateLoanApplication(
            options.LoanApplicationID,
            {
              MarketingConsentID: marketingConsent.ID,
            },
            {
              Authorization: options.Authorization,
            },
          );
        }
      },

      Debt: async (key, changes) => {
        const id = getFormState().fieldNameToBackendIDMap[key];
        if (!id) {
          const debtID = await this.createDebtObject(changes as IProtoDebt, {
            Authorization: options.Authorization,
            LoanApplicationID: options.LoanApplicationID,
          });
          dispatch({
            type: 'field-name-to-backend-id-map.add',
            backendID: debtID,
            fieldName: key,
          });
        } else {
          await this.updateDebtObject(id, changes, {
            Authorization: options.Authorization,
            LoanApplicationID: options.LoanApplicationID,
          });
        }
      },

      CoPerson: async (_, changes) => {
        if (changes.SocialSecurityNumber && changes.MarketCountry) {
          const person = await this.createPerson(changes as IProtoPerson, {
            Authorization: options.Authorization,
          });
          return this.updateLoanApplication(
            options.LoanApplicationID,
            {
              CoApplicantPersonID: person.ID,
            },
            { Authorization: options.Authorization },
          );
        } else {
          // If we dont get ssn from changes it could be that they are hidden and are nulled in the surveyCompleting step, so we need to remove CoApplicantPersonId from the application.
          return this.updateLoanApplication(
            options.LoanApplicationID,
            {
              CoApplicantPersonID: null,
            },
            { Authorization: options.Authorization },
          );
        }
      },

      Person: async (_, changes) => updatePerson(changes, options),
      LoanApplication: (_, changes) =>
        this.updateLoanApplication(options.LoanApplicationID, changes, {
          Authorization: options.Authorization,
        }),

      AmountOfChildren: (_, changes) =>
        this.updateAmountOfChildren(parseInt(changes.AmountOfChildren || ''), {
          Authorization: options.Authorization,
          LoanApplicationID: options.LoanApplicationID,
        }),
    });

    return changeHandler(data);
  }

  abstract createCustomer(options: IProtoCustomer): Promise<ICustomer>;

  protected abstract updateCustomer(
    id: string,
    data: IPartialCustomer,
    options: IClientAuthorizationHeaderOption,
  ): Promise<ICustomerWithoutJWT>;

  abstract getCustomer(
    id: string,
    headers: IClientAuthorizationHeaderOption,
  ): Promise<ICustomerWithoutJWT>;

  abstract sendEmailVerificationPin(customerId: string): Promise<void>;

  abstract getVerifiedJWT(
    customerId: string,
    pin: string,
  ): Promise<IAuthorizationToken>;

  abstract createPerson(
    data: Pick<IPerson, 'MarketCountry' | 'SocialSecurityNumber'>,
    headers: IClientAuthorizationHeaderOption,
  ): Promise<IPerson>;

  abstract updatePerson(
    id: string,
    data: Pick<IPerson, 'MarketCountry' | 'SocialSecurityNumber'>,
    headers: IClientAuthorizationHeaderOption,
  ): Promise<IPerson>;

  abstract getPerson(
    personID: string,
    headers: IClientAuthorizationHeaderOption,
  ): Promise<IPerson>;

  abstract createLoanApplication(
    data: IProtoLoanApplication,
    headers: IClientAuthorizationHeaderOption,
  ): Promise<ILoanApplication>;

  abstract updateLoanApplication(
    id: string,
    data: IPartialLoanApplication,
    options: IClientAuthorizationHeaderOption,
  ): Promise<void>;

  abstract getLoanApplication(
    loanApplicationId: string,
    headers: IClientAuthorizationHeaderOption,
  ): Promise<ILoanApplication>;

  abstract getPersistanceValues(
    magicTokenUUID: string,
  ): Promise<IPersistanceValues>;

  abstract createMarketingConsent(
    data: Partial<IConsent>,
    headers: IClientAuthorizationHeaderOption,
  ): Promise<IConsent>;

  abstract revokeMarketingConsent(
    customerId: string,
    headers: IClientAuthorizationHeaderOption,
  ): Promise<void>;

  abstract getMarketingConsent(
    id: string,
    headers: IClientAuthorizationHeaderOption,
  ): Promise<IConsent>;

  protected abstract updateDebtObject(
    id: string,
    data: IPartialDebt,
    options: IClientAuthorizationHeaderOption & IClientApplicationIdOption,
  ): Promise<void>;

  protected abstract createDebtObject(
    data: IProtoDebt,
    options: IClientAuthorizationHeaderOption & IClientApplicationIdOption,
  ): Promise<string>;

  abstract getAllDebtObjects(
    loanApplicationId: string,
    headers: IClientAuthorizationHeaderOption,
  ): Promise<IDebt[]>;

  protected abstract updateAmountOfChildren(
    amount: number,
    options: IClientAuthorizationHeaderOption & IClientApplicationIdOption,
  ): Promise<void>;

  abstract getChildren(
    options: IClientAuthorizationHeaderOption & IClientApplicationIdOption,
  ): Promise<IChild[]>;
}

export class ClientError extends Error {}
