import { getFormState } from './../store/store';
import client from './client';
export const sendChannelSnapshot = async () => {
  const { loanApplication, customer, channelSnapshot } = getFormState();

  if (!loanApplication) {
    throw new Error(
      'Could not find a loan application (required to complete a step).',
    );
  }
  if (!customer) {
    throw new Error('Missing customer');
  }
  if (!customer.JWT) {
    throw new Error('Missing customer JWT');
  }

  await client.addChannelSnapshot(channelSnapshot, {
    LoanApplicationID: loanApplication.ID,
    Authorization: customer.JWT,
  });
};
