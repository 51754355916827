import { withMonitoring } from './monitoring/withMonitoring';

const customFetch: typeof window.fetch = (input, init): Promise<Response> => {
  return withMonitoring(() => withThrowError(() => fetch(input, init))).then(
    (response) => response,
  );
};

const withThrowError = async (
  fn: () => Promise<Response>,
): Promise<Response> => {
  const response = await fn();

  if (response.status >= 400 || response.status === 0) {
    const data = await response.json();
    throw new HttpError(
      `Request failed with status: ${response.status} ${
        data?.message ?? data
      }\n`,
      response,
    );
  }

  return response;
};

export class HttpError extends Error {
  public override readonly name = 'HttpError';
  public readonly statusCode: number;
  public readonly url: string;

  constructor(message: string, response: Response) {
    super(message);
    this.statusCode = response.status;
    this.url = response.url;
  }
}

export default customFetch;
