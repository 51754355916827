import { addStepCompleteBreadcrumb } from './../../utils/monitoring/addStepCompleteBreadcrumb';
import { getStepName } from '../../utils/getStepName';
import { sendChannelSnapshot } from '../../backend-sync/sendChannelSnapshot';
import { ISurveyOnPageChanged } from '../../types/survey/ISurveyOnPageChanged';
import { completeStep } from '../../backend-sync/completeStep';
import { SurveyModel } from 'survey-core';

const surveyPageChanged = async (
  survey: SurveyModel,
  options: ISurveyOnPageChanged,
) => {
  if (options.isNextPage && options.oldCurrentPage.num === 1) {
    // Emit an event when step1 is filled out. This might trigger a redirect in the parent website.
    const event = new Event('AxoFormStep1Complete');
    document.dispatchEvent(event);
  }

  if (options.isNextPage) {
    await completeStep(getStepName(options.oldCurrentPage));
    addStepCompleteBreadcrumb({
      message: `${getStepName(options.oldCurrentPage)} completed`,
      data: survey.data,
    });
  }

  sendChannelSnapshot();
};

export default surveyPageChanged;
