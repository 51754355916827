import {
  ICustomer,
  IPartialCustomer,
  IProtoCustomer,
} from '../../../types/data/ICustomer';

export function isAChangeToCustomerEmail(
  oldCustomer: ICustomer,
  changes: IPartialCustomer,
): changes is IProtoCustomer {
  return Boolean(changes.Email && changes.Email !== oldCustomer.Email);
}
