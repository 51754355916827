import { z } from 'zod';
import { EventName } from './types';

export const emitEvent = (
  name: EventName,
  schema: z.ZodSchema,
  payload: z.infer<typeof schema>['details'],
) =>
  document.dispatchEvent(
    new CustomEvent(name, { detail: schema.parse(payload) }),
  );
