/**
 * Applies a prefix to the keys of an object.
 *
 * @param {T} obj - The object to prefix the keys of.
 * @param {string} prefix - The prefix to be added to each key.
 * @returns {Record<string, unknown>} - The new object with prefixed keys.
 */
export const prefixAttributes = <T extends Record<string, unknown>>(
  obj: T,
  prefix: string,
) => {
  return Object.keys(obj).reduce((sum, key) => {
    sum[prefix + key] = obj[key];
    return sum;
  }, {} as Record<string, unknown>);
};
