import { HttpError } from '../../../utils/customFetch';
import { IAuthorizationToken } from '../../../types/data/IAuthorizationToken';
import { IPartialCustomer } from '../../../types/data/ICustomer';
import { dispatch, getFormState } from '../../../store';
import client from '..';
import { isAChangeToCustomerEmail } from './isAChangeToCustomerEmail';
import { replaceCustomer } from './replaceCustomer';

export const updateCustomer = async (
  changes: IPartialCustomer,
  options: {
    JWT: IAuthorizationToken;
  },
): Promise<void> => {
  const { customer: oldCustomer, loanApplication } = getFormState();
  if (!oldCustomer) {
    return console.warn(
      'Could not update customer before creating the customer',
    );
  }

  try {
    const newCustomer = await (async () => {
      if (isAChangeToCustomerEmail(oldCustomer, changes)) {
        return await replaceCustomer(oldCustomer, changes, loanApplication?.ID);
      } else {
        return await client.updateCustomer(oldCustomer.ID, changes, {
          Authorization: options.JWT,
        });
      }
    })();

    dispatch({
      type: 'customer.replaced',
      customer: {
        JWT: options.JWT,
        ...newCustomer,
      },
    });

    dispatch({ type: 'auth.verification.obtained' });
  } catch (error) {
    if (error instanceof HttpError && error.statusCode === 401) {
      dispatch({ type: 'auth.verification.required' });
    } else {
      throw error;
    }
  }
};
