import { changeHandler } from './handle-changes/index';
import { isAuthorizationToken } from './../types/data/IAuthorizationToken';
import { runAsStatefulAsync } from './../utils/statefulAsync';
import { dispatch, getFormState } from '../store/store';
import client from './client';
import { runWithAutomaticAndManualRetry } from '../utils/runWithAutomaticAndManualRetry';
import { IChanges } from '../types/data/IChanges';

export const handleInitialSetup = async (data: IChanges) => {
  const {
    'Customer/Email': Email,
    'Customer/MobilePhoneNumber': MobilePhoneNumber,
    'Customer/MobilePhoneCountryCode': MobilePhoneCountryCode,
    'LoanApplication/MarketCountry': LoanApplicationMarketCountry,
  } = data;

  if (!Email) throw new Error('Missing customer email');
  if (!MobilePhoneNumber)
    throw new Error('Missing customer mobile phone number');
  if (!MobilePhoneCountryCode)
    throw new Error('Missing mobile phone country code');

  await runAsStatefulAsync(async () => {
    await runWithAutomaticAndManualRetry([
      async () => {
        // CREATE CUSTOMER
        const customer = await client.createCustomer({
          Email,
        });

        if (!customer.JWT) {
          throw new Error('Missing JWT in customer');
        }

        dispatch({
          type: 'customer.created',
          customer: customer,
        });
      },
      async () => {
        // CREATE LOAN APPLICATION
        const { customer } = getFormState();
        if (!customer) throw new Error('Missing customer');

        // TODO: If customer is undefined JWT will also be.
        const { JWT } = customer;

        if (!isAuthorizationToken(JWT)) throw new Error('Invalid JWT');
        if (!LoanApplicationMarketCountry)
          throw new Error('Missing LoanApplication/MarketCountry');

        const loanApplication = await client.createLoanApplication(
          {
            CustomerID: customer.ID,
            MarketCountry: LoanApplicationMarketCountry,
          },
          {
            Authorization: JWT,
          },
        );

        dispatch({
          type: 'loan-application.created',
          loanApplication,
        });
      },

      async () => {
        // SEND CURRENT FIELD VALUES
        await changeHandler.onSetupComplete(data);
      },
    ]);
  });
};
