import retryFailedAfterDelay from './retryFailedAfterDelay';
import runWithManualRetry from './runWithManualRetry';

export const runWithAutomaticAndManualRetry: typeof runWithManualRetry = async (
  stepOrSteps,
) => {
  const steps = Array.isArray(stepOrSteps) ? stepOrSteps : [stepOrSteps];
  const s = steps.map((step) => async () => await retryFailedAfterDelay(step));
  await runWithManualRetry(s);
};
