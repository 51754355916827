import { sendGAEvent } from './../../utils/sendGAEvent';
import { SurveyModel, PageModel } from 'survey-core';
import setTag from '../../utils/monitoring/setTag';

export const onPageLoad = (
  survey: SurveyModel,
  { page }: { page: PageModel; htmlElement: HTMLElement },
) => {
  sendGAEvent({
    event: 'GAEvent',
    eventCategory: 'Form',
    eventAction: `Step ${page.num}`,
    eventLabel: '',
    page: {
      title: page.name,
      url: `/${page.name
        .replaceAll(' ', '-')
        .replaceAll("'", '')
        .replaceAll(':', '')
        .toLowerCase()}`,
    },
  });

  setTag('axoform.step.name', page.name);
  setTag('axoform.step', page.num);
};
