export const LOAN_AMOUNT = 'loanAmount';
export const LOAN_DURATION = 'loanDuration';

export const validSearchParams = {
  [LOAN_AMOUNT]: 'LoanApplication/AppliedAmount',
  [LOAN_DURATION]: 'LoanApplication/LoanDuration',
} as const;

export const searchParamIsValid = (
  keyFromQuery: unknown,
): keyFromQuery is keyof typeof validSearchParams => {
  return (
    typeof keyFromQuery === 'string' &&
    Object.keys(validSearchParams).includes(keyFromQuery)
  );
};
