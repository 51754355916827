import { Model } from 'survey-core';
import { z } from 'zod';

const schema = z.object({
  refinancing: z.boolean().optional(),
});
type IValidProperties = z.infer<typeof schema>;
type IValidKeys = keyof z.infer<typeof schema>;

export const setDefaultValuesFromDataAttributes = (
  data: IValidProperties,
  surveyJsForm: Model,
) => {
  const validatedData = schema.strict().parse(data);

  Object.entries(validatedData).forEach(([key, value]) => {
    const action = keyActions[key as IValidKeys];
    if (action) {
      action(surveyJsForm, value);
    }
  });
};

const handleDefaultRefinancing = (surveyJsForm: Model, value?: boolean) => {
  if (value) {
    surveyJsForm.getQuestionByName('LoanApplication/Purpose').value =
      'Refinancing loan';
  }
};

const keyActions: Record<
  IValidKeys,
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  (surveyJsForm: Model, value: any) => void
> = {
  refinancing: handleDefaultRefinancing,
};
