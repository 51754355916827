import { useEffect, useState } from 'react';
import reloadState from '../backend-sync/reloadState';
import {
  IPersistanceValues,
  PersistanceValuesSchema,
} from '../types/IPersistanceValues';
import { getFormState, dispatch } from '../store';
import injectData from '../survey/injectData';
import client from '../backend-sync/client';
import { SurveyModel } from 'survey-core';

enum SessionStorageKeys {
  LOAN_APPLICATION_ID = 'axo.application-id',
  TOKEN = 'axo.token',
}

export const clearReloadData = () => {
  sessionStorage.removeItem(SessionStorageKeys.TOKEN);
  sessionStorage.removeItem(SessionStorageKeys.LOAN_APPLICATION_ID);
};

export const useReloadFromBackendOnInit = (survey: SurveyModel | undefined) => {
  useSaveIdsAndJWTWhenUpdated();
  useReloadAndInjectDataToSurvey(survey);
};

const useSaveIdsAndJWTWhenUpdated = () => {
  const { customer, loanApplication } = getFormState();

  useEffect(() => {
    if (!customer?.JWT || !loanApplication?.ID) return;

    sessionStorage.setItem(SessionStorageKeys.TOKEN, customer.JWT);
    sessionStorage.setItem(
      SessionStorageKeys.LOAN_APPLICATION_ID,
      loanApplication.ID,
    );
  }, [customer?.JWT, loanApplication?.ID]);
};

const useReloadAndInjectDataToSurvey = (survey: SurveyModel | undefined) => {
  const { customer, loanApplication } = getFormState();
  const params = new URL(window.location.href).searchParams;

  const [hasStartedOrCompletedReload, setHasStartedOrCompletedReload] =
    useState<boolean>(false);

  useEffect(() => {
    const needsReload = !customer || !loanApplication;
    if (hasStartedOrCompletedReload) return;
    if (!needsReload) return;
    if (!survey) return;

    setHasStartedOrCompletedReload(true);

    const magicToken = params.get('token');
    if (magicToken) {
      dispatch({ type: 'magic.fetching' });

      client.getPersistanceValues(magicToken).then((persistanceValues) => {
        dispatch({ type: 'magic.resolved' });
        reload(persistanceValues, survey);
      });
    } else {
      const persistanceValues = PersistanceValuesSchema.safeParse({
        loanApplicationId: sessionStorage.getItem(
          SessionStorageKeys.LOAN_APPLICATION_ID,
        ),
        JWT: sessionStorage.getItem(SessionStorageKeys.TOKEN),
      });

      if (persistanceValues.success) {
        reload(persistanceValues.data, survey);
      } else {
        dispatch({ type: 'form.ready' });
      }
    }
  }, [customer, hasStartedOrCompletedReload, loanApplication, params, survey]);
};

const reload = (persistanceValues: IPersistanceValues, survey: SurveyModel) => {
  dispatch({ type: 'form.initializing' });

  reloadState(persistanceValues)
    .then((reloadedData) => {
      injectData(survey, {
        ...reloadedData,
      });
      dispatch({ type: 'form.restored' });
    })
    .catch((err) => {
      console.error('Restoring form state failed!\n\n', err);
      dispatch({ type: 'form.ready' });
    });
};
