import { HttpError } from './customFetch';

/**
 * Can be used for handling a specific http status code from a fetch request.
 *
 * For example (this will handle all 404 responses):
 * promise.catch(forStatusCode(404, () => {...}))
 */
export const forStatusCode = <T>(
  statusCode: number,
  errorHandler: (e: HttpError) => T,
): ((e: unknown) => T) => {
  return (e) => {
    if (e instanceof HttpError && e.statusCode === statusCode) {
      return errorHandler(e);
    }
    throw e;
  };
};

/**
 * When used as the second parameter to forStatusCode, the specified status code
 * will be silently ignored.
 */
export const ignore = () => undefined;
