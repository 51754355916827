import { debounceFunction } from './../../utils/debounce';
import { IChanges } from '../../types/data/IChanges';
import { sendChanges } from './sendChanges';
import { dispatch } from '../../store';
import { getChangeDependencies } from './getChangeDependencies';

/**
 * @param filter
 * @param {RegExp} filter.include If set, the change handler will accept only
 * changes that match this pattern.
 * @param {RegExp} filter.exclude If set, the change handler will ignore all
 * changes that match this pattern.
 */
export const ChangeHandler = ({
  include,
  exclude,
}: {
  include?: RegExp;
  exclude?: RegExp;
}) => {
  let changes: IChanges = {};
  let cache: IChanges = {};

  const isInProgress = () => Object.keys(cache).length > 0;
  const registerChanges = (newChanges: IChanges) => {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    Object.keys(newChanges).forEach((key: any) => {
      const matchesInclude = !include || include.test(key),
        doesNotMatchExclude = !exclude || !exclude.test(key);

      if (matchesInclude && doesNotMatchExclude) {
        changes[key] = newChanges[key];
      }
    });
  };

  const send = (): Promise<void> => {
    const deps = getChangeDependencies();

    if (!deps.hasDependencies) return Promise.resolve();
    if (isInProgress()) {
      return Promise.reject(
        new Error('Sending changes blocked by changes being sent'),
      );
    }

    cache = { ...changes };
    changes = {};

    return sendChanges({
      changes: cache,
      dependencies: deps.dependencies,
    })
      .catch((err) => {
        changes = { ...cache, ...changes };
        throw err;
      })
      .finally(() => {
        cache = {};

        if (JSON.stringify(changes) === '{}') {
          dispatch({ type: 'changes.resolved' });
        }
      });
  };

  const debouncedSend = debounceFunction(send, 1000);

  return { registerChanges, send, debouncedSend };
};
