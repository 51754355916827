import { useEffect } from 'react';
import { useSearchParams } from 'react-router-dom';
import useFormState from './useFormState';
import { getChannelSnapshot } from '../utils/getChannelSnapshot';

export const useSyncChannelSnapshotToState = () => {
  const [searchParams] = useSearchParams();
  const [{ completedSteps }, dispatch] = useFormState();

  useEffect(() => {
    const channelSnapshot = getChannelSnapshot(searchParams, completedSteps);

    dispatch({
      type: 'channel-snapshot.updated',
      channelSnapshot,
    });
  }, [completedSteps, dispatch, searchParams]);
};
