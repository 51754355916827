import { utmParametersSchema } from '../types/utmParameters';
import { useEffect } from 'react';
import { useSearchParams } from 'react-router-dom';

const hasOffersAffiliateUrl = document
  .getElementById('axo-root')
  ?.getAttribute('data-has-offers-affiliate-url');

export const useSetUtmParametersFromAffiliateTrackingLinkOnMount = () => {
  const [searchParams, setSearchParams] = useSearchParams();
  useEffect(() => {
    if (hasOffersAffiliateUrl) {
      fetch(
        `${
          process.env.REACT_APP_INTEGRATION_URL
        }/v1/loan-application/affiliate-tracking?${new URLSearchParams({
          hasOffersAffiliateUrl,
        })}`,
        {},
      )
        .then((res) => res.json())
        .then(utmParametersSchema.parse)
        .then(({ Source, Medium, Term, Content, Name }) => {
          setSearchParams({
            ...Object.fromEntries(searchParams),
            utm_source: Source ?? '',
            utm_medium: Medium ?? '',
            utm_term: Term ?? '',
            utm_content: Content ?? '',
            utm_name: Name ?? '',
          });
        })
        .catch(console.error);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
};
