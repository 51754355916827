import { useEffect, useMemo, useState } from 'react';
import {
  Model,
  PageModel,
  Question,
  SurveyError,
  SurveyModel,
} from 'survey-core';
import { Survey } from 'survey-react-ui';
import { Serializer } from 'survey-core';
import { ErrorAlert } from '@a2755/ui-components';
import { SurveyStateContext } from '@a2755/ui-components/dist/questions';
import useFormState from './hooks/useFormState';
import softVerifyFieldNames from './utils/verifyFieldNames';
import {
  surveyPageChanging,
  surveyCompleting,
  surveyValueChanged,
} from './survey';
import surveyPageChanged from './survey/event-handlers/surveyPageChanged';
import { useReloadFromBackendOnInit } from './hooks/useReloadFromBackendOnInit';
import { useContinueFromFirstError } from './hooks/useContinueFromFirstError';
import { useSyncChannelSnapshotToState } from './hooks/useSyncChannelSnapshotToState';
import { addPageStructure } from './survey/add-page-structure';
import { IConfig } from './types/survey/IConfig';
import { useSearchParams } from 'react-router-dom';
import { surveyAfterRenderPage } from './survey/event-handlers/surveyAfterRenderPage';
import { EmailVerificationModal } from './components/EmailVerificationModal';
import { changeHandler } from './backend-sync';
import { useNavigateByQueryParams } from './hooks/useNavigateByQueryParams';
import setTag from './utils/monitoring/setTag';
import { setFormValuesFromQueryParams } from './utils/setFormValuesFromQueryParams';
import { registerQuestions } from './survey/questions';
import localization from './localization';
import { ISurveyOnValueChangedOptions } from './types/survey/ISurveyOnValueChangedOptions';
import { eventConsumer, formEventProducer } from './utils/events';
import { setDefaultValuesFromDataAttributes } from './utils/SetDefaultValuesFromDataAttributes';

registerQuestions();

function surveyValidateQuestion(
  s: SurveyModel,
  options: { errors: SurveyError[] },
) {
  if (options?.errors) {
    const errors = options.errors;
    for (let i = 0; i < errors.length; i += 1) {
      errors[i].visible = false;
    }
  }
}

const myCss = {
  page: {
    root: 'form_page_root',
  },
  question: {
    indent: 0,
    mainRoot: 'question_root',
  },
  panel: {
    title: 'sv_p_title',
    titleExpandable: 'sv_p_title_expandable',
    titleExpanded: 'sv_p_title_expanded',
    titleCollapsed: 'sv_p_title_collapsed',
    titleOnError: '',
    icon: 'sv_panel_icon',
    iconExpanded: 'sv_expanded',
    description: 'small sv_p_description',
    content: 'content-axoform',
    container: 'container-axoform',
    footer: 'sv_p_footer',
    number: 'sv_q_num',
    requiredText: 'sv_q_required_text',
  },
};

const Form = () => {
  const [searchParams] = useSearchParams();
  const [survey, setSurvey] = useState<Model>();
  const [formState] = useFormState();
  const { setupState } = formState;

  useEffect(() => {
    const unsubscribe = eventConsumer.abtest.ready((e) => {
      if (e.detail.formValues.loanAmount) {
        survey?.setValue(
          'LoanApplication/AppliedAmount',
          e.detail.formValues.loanAmount,
        );
      }
    });

    return () => unsubscribe();
  }, [survey]);

  useEffect(() => {
    if (formState.setupState === 'ready')
      setTimeout(() => formEventProducer.emit.ready(), 1);
  }, [formState.setupState]);

  useEffect(() => {
    const url = document
      .querySelector('#axo-root')
      ?.getAttribute('data-config');
    if (!url) {
      return;
    }

    setTag('axoform.config.url', url);

    fetch(url)
      .then((response) => response.json())
      .then((data: IConfig) => {
        const dataWithStructure = addPageStructure(
          data.surveyConfig,
          data.surveyPageStructure,
        );
        const _survey = new Model(dataWithStructure);
        softVerifyFieldNames(_survey);
        return setSurvey(_survey);
      })
      .catch((e) => console.log(e));
  }, []);

  // Since we dont have control over surveyJS internal html, we set this ID by javascript. ID is used by VWO to add tracking on the form.
  useEffect(() => {
    if (setupState === 'idle') {
      setFormValuesFromQueryParams(survey);
    }

    if (setupState === 'ready') {
      const form = document.getElementsByTagName('form')[0];
      form.id = 'axo-form-root';
      form.noValidate = true;

      const defaultValuesString = document
        .querySelector('#axo-root')
        ?.getAttribute('data-default-values');

      if (defaultValuesString) {
        if (!defaultValuesString.includes('undefined')) {
          const parsedData = JSON.parse(defaultValuesString);
          if (parsedData && survey) {
            setDefaultValuesFromDataAttributes(parsedData, survey);
          }
        }
      }
    }
  }, [setupState, survey]);

  const localeFromUrl = searchParams.get('locale');
  const localeFromAttribute = document
    .querySelector('#axo-root')
    ?.getAttribute('data-default-locale');
  const locale = localeFromUrl || localeFromAttribute || '';

  if (locale && survey) {
    survey.locale = locale;
  }
  const translations = useMemo(() => localization(locale), [locale]);

  Serializer.findProperty('question', 'minWidth').defaultValue = 'auto';
  useSyncChannelSnapshotToState();
  useReloadFromBackendOnInit(survey);
  useContinueFromFirstError(survey);
  useNavigateByQueryParams(survey);

  const [changeCounter, setChangeCounter] = useState(0);

  if (formState.setupState !== 'restored' && formState.setupState !== 'ready')
    return null;

  return (
    <SurveyStateContext.Provider
      value={{
        hasPendingRequest: formState.loadingState === 'pending',
      }}
    >
      <div style={{ display: 'none' }}>
        {/* Sometimes SurveyJS won't rerender the page even though data has changed 
        (e.g. when updating dynamic titles/labels). This hack ensures that the form
        is rerendered each time any value changes, so that dynamic ui that depends 
        on the form values is always updated. */}
        {changeCounter}
      </div>
      {survey && (
        <Survey
          model={survey}
          css={myCss}
          onSettingQuestionErrors={surveyValidateQuestion}
          onCurrentPageChanging={surveyPageChanging}
          onCurrentPageChanged={surveyPageChanged}
          onCompleting={surveyCompleting}
          onAfterRenderPage={surveyAfterRenderPage}
          onValueChanged={(s: SurveyModel, o: ISurveyOnValueChangedOptions) => {
            setChangeCounter((prev) => ++prev);
            surveyValueChanged(s, o);
          }}
        />
      )}
      {formState.emailVerification === 'required' && (
        <EmailVerificationModal
          email={survey?.data['Customer/Email']}
          onVerify={() => {
            if (!survey) return;
            const values = (
              (survey.currentPage as PageModel).questions as Question[]
            ).reduce((obj, q) => ({ ...obj, [q.name]: q.value }), {});

            changeHandler.onChange(values);
            survey.nextPage();
          }}
        />
      )}
      {formState.errors.length > 0 && (
        <ErrorAlert
          title={translations.genericErrorAlert.title}
          paragraphs={translations.genericErrorAlert.paragraphs}
          buttonText={translations.genericErrorAlert.retry}
          onButtonClick={async () => {
            for (const error of formState.errors) {
              await error.retry();
            }
          }}
        />
      )}
    </SurveyStateContext.Provider>
  );
};

export default Form;
