import { getFormState } from '../store';
import client from './client';

export const completeApplication = async (finalState: unknown) => {
  const { customer, loanApplication } = getFormState();

  if (!loanApplication) {
    throw new Error('Could not find a loan application to complete.');
  }
  if (!customer) {
    throw new Error('Missing customer');
  }
  if (!customer.JWT) {
    throw new Error('Missing JWT');
  }

  await client.applicationCompleted(finalState, {
    LoanApplicationID: loanApplication.ID,
    Authorization: customer.JWT,
  });
};
