import { z } from 'zod';
import { newListener } from '../core/newListener';

const NAME = 'axo.abtest.ready';

const schemas = {
  v1: z.object({
    formValues: z
      .object({
        loanAmount: z.number().optional(),
      })
      .optional()
      .default({}),
  }),
};

export const listenAbtestReady = newListener(`${NAME}/v1`, schemas.v1);
