import { SurveyModel } from 'survey-core';
import { ISurveyOnValueChangedOptions } from '../../../types/survey/ISurveyOnValueChangedOptions';
import { syncFieldsWithDefaultValues } from '../syncFieldsWithDefaultValues';
import { syncFieldsThatWereFixed } from './syncFieldsThatWereFixed';
import { syncField } from './syncFields';

export const surveyValueChanged = (
  survey: SurveyModel,
  options: ISurveyOnValueChangedOptions,
) => {
  if (!options?.question) {
    return console.warn('Missing question in change event handler', options);
  }

  syncFieldsWithDefaultValues(survey);
  syncFieldsThatWereFixed(survey);
  if (options.question.hasErrors()) return;

  if (options.name === 'Person/SocialSecurityNumber') {
    syncField({
      name: 'Person/MarketCountry',
      value: survey.getCalculatedValueByName('Person/MarketCountry').value,
    });
  }
  if (options.name === 'CoPerson/SocialSecurityNumber') {
    syncField({
      name: 'CoPerson/MarketCountry',
      value: survey.getCalculatedValueByName('Person/MarketCountry').value,
    });
  }

  syncField(options);
};
