/* eslint-disable @typescript-eslint/no-explicit-any */
import React from 'react';
import { ElementFactory, Question, Serializer } from 'survey-core';
import {
  SurveyQuestionElementBase,
  ReactQuestionFactory,
} from 'survey-react-ui';

class QuestionModel extends Question {
  questionType: string;

  constructor(questionType: string, name: string) {
    super(name);
    this.questionType = questionType;
  }

  getType(): string {
    const type = this.questionType ?? '';
    return type;
  }

  get aggregateValue() {
    return this.getPropertyValue('aggregateValue');
  }

  set aggregateValue(newValue: string) {
    this.setPropertyValue('aggregateValue', newValue);
  }

  get dependencies() {
    return this.getPropertyValue('dependencies');
  }

  set dependencies(newValue: string[]) {
    this.setPropertyValue('dependencies', newValue);
  }

  /**
   * Key-value pairs of field names and initial values, like this:
   * { "field name": "initial value" }
   */
  get init() {
    return this.getPropertyValue('init', {});
  }

  set init(newValue: Record<string, string>) {
    this.setPropertyValue('init', newValue);
  }
  get setIf() {
    return this.getPropertyValue('setIf');
  }

  set setIf(newValue: string) {
    this.setPropertyValue('setIf', newValue);
  }
}

export class SurveyQuestion extends SurveyQuestionElementBase {
  constructor(props: any) {
    super(props);

    for (const fieldName in this.question.init) {
      const initialValue = this.question.survey.runExpression(
        this.question.init[fieldName],
      );
      const shouldSetValue = this.question.setIf
        ? this.question.survey.runExpression(this.question.setIf)
        : true;

      if (shouldSetValue) {
        this.question.survey.getQuestionByName(fieldName).value = initialValue;
      } else {
        this.question.survey.getQuestionByName(fieldName).value = undefined;
      }
    }

    this.question.dependencies.forEach((key) => {
      const dependency = this.question.survey.getQuestionByName(key);
      if (!dependency) {
        console.warn('Could not find dependency for static value: ' + key);
        return;
      }
      dependency.registerFunctionOnPropertyValueChanged(
        'value',
        this.updateAggregateValue.bind(this),
        `${dependency.name}-${this.question.name}`,
      );
    });

    this.updateAggregateValue();
  }

  get question() {
    return this.questionBase as QuestionModel;
  }

  private updateAggregateValue() {
    const shouldSetValue = this.question.setIf
      ? this.question.survey.runExpression(this.question.setIf)
      : true;

    setTimeout(() => {
      this.question.value = undefined; // Forces the change handler to trigger by making sure the next line is a change
      setTimeout(() => {
        const expressionValue = this.question.survey.runExpression(
          this.question.aggregateValue,
        );

        if (!shouldSetValue) return;

        this.question.value = expressionValue;
      }, 10); // Make sure survey updates first
    }, 10);
  }

  protected renderElement(): JSX.Element {
    return <></>;
  }
}

export function registerAggregateValueQuestion() {
  const questionType = 'Axo Aggregate Value';

  // this serializes the class into JSON
  Serializer.addClass(
    questionType,

    // Add properties that should be accessed in Creator.
    [
      {
        name: 'aggregateValue',
        category: 'general',
        type: 'string',
      },
      {
        name: 'dependencies',
        category: 'general',
        type: 'list',
      },
      {
        name: 'init',
        category: 'general',
        type: 'object',
      },
      {
        name: 'setIf',
        category: 'general',
        type: 'string',
      },
    ],
    () => new QuestionModel(questionType, ''),
    'question',
  );

  ReactQuestionFactory.Instance.registerQuestion(questionType, (props: any) =>
    React.createElement(SurveyQuestion, props),
  );

  ElementFactory.Instance.registerElement(questionType, (name: string) => {
    return new QuestionModel(questionType, name);
  });
}
