import { z, ZodType } from 'zod';
import {
  isObject,
  propertyIsOptionalString,
  propertyIsString,
} from './validation';

export const AuthorizationTokenSchema = z
  .string()
  .regex(/.+\..+\..+/) as ZodType<`${string}.${string}.${string}`>;

export type IAuthorizationToken = z.infer<typeof AuthorizationTokenSchema>;

export const isAuthorizationToken = (
  input: unknown,
): input is IAuthorizationToken => {
  return AuthorizationTokenSchema.safeParse(input).success;
};

export const propertyIsAuthorizationToken = <P extends string>(
  input: unknown,
  property: P,
): input is { [Property in P]: IAuthorizationToken } => {
  if (!isObject(input)) return false;
  if (!propertyIsString(input, property)) return false;

  return isAuthorizationToken(input[property]);
};

export const propertyIsOptionalAuthorizationToken = <P extends string>(
  input: unknown,
  property: P,
): input is { [Property in P]: IAuthorizationToken } => {
  if (!isObject(input)) return false;
  if (!propertyIsOptionalString(input, property)) return false;

  const value = input[property];
  if (typeof value === 'undefined') return true;

  return isAuthorizationToken(value);
};
