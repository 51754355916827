import { ICustomer, IProtoCustomer } from '../../../types/data/ICustomer';
import client from '..';

export async function replaceCustomer(
  oldCustomer: ICustomer,
  changes: IProtoCustomer,
  loanApplicationID?: string,
): Promise<ICustomer> {
  const customer = await client.createCustomer({
    Email: changes.Email,
  });

  const newCustomer = await client.updateCustomer(customer.ID, changes, {
    Authorization: customer.JWT,
  });

  if (loanApplicationID) {
    await client.updateLoanApplication(
      loanApplicationID,
      {
        CustomerID: customer.ID,
      },
      // We need to patch in the new customer ID with the old customer JWT. After this is done, the new JWT can be used.
      { Authorization: oldCustomer.JWT },
    );
  }

  // JWT is not returned with PATCH to customer endpoint, only POST is returning JWT. Add JWT to the customer object.
  return { ...newCustomer, JWT: customer.JWT };
}
