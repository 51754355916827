import Cookie from 'js-cookie';

export default Cookie.withConverter({
  read: (value: string, name: string) => Cookie.converter.read(value, name),

  write: (value: { [key: string]: string } | string, name: string): string => {
    if (typeof value === 'object' && value !== null) {
      value = Object.entries(value)
        .map(([key, value]) => `${key}=${value}`)
        .join('|');
    }
    return Cookie.converter.write(value, name);
  },
});
