/* eslint-disable @typescript-eslint/no-explicit-any */
import React from 'react';
import { ElementFactory, Question, Serializer } from 'survey-core';
import {
  SurveyQuestionElementBase,
  ReactQuestionFactory,
} from 'survey-react-ui';
import styles from './HorizontalSeparator.module.scss';

class QuestionModel extends Question {
  questionType: string;

  constructor(questionType: string, name: string) {
    super(name);
    this.questionType = questionType;
  }

  getType(): string {
    const type = this.questionType ?? '';
    return type;
  }
}

export class SurveyQuestion extends SurveyQuestionElementBase {
  protected renderElement(): JSX.Element {
    return <hr className={styles.hr}></hr>;
  }
}

export function registerHorizontalSeparatorQuestion() {
  const questionType = 'Axo Horizontal Separator';

  // this serializes the class into JSON
  Serializer.addClass(
    questionType,

    // Add properties that should be accessed in Creator.
    [],
    () => new QuestionModel(questionType, ''),
    'question',
  );

  ReactQuestionFactory.Instance.registerQuestion(questionType, (props: any) =>
    React.createElement(SurveyQuestion, props),
  );

  ElementFactory.Instance.registerElement(questionType, (name: string) => {
    return new QuestionModel(questionType, name);
  });
}
