import { dispatch } from './../store/store';
import { getFormState } from '../store';
import client from './client';

export const completeStep = async (stepName: string) => {
  const { customer, loanApplication, completedSteps } = getFormState();
  if (completedSteps.includes(stepName)) return;

  if (!loanApplication) {
    throw new Error(
      'Could not find a loan application (required to complete a step).',
    );
  }
  if (!customer) {
    throw new Error('Missing customer');
  }
  if (!customer.JWT) {
    throw new Error('Missing customer JWT');
  }

  await client.stepCompleted(stepName, {
    LoanApplicationID: loanApplication.ID,
    Authorization: customer.JWT,
  });

  dispatch({
    type: 'step.complete',
    step: stepName,
  });
};
