import { setTag as setSentryTag } from '@sentry/react';
import { Primitive } from '@sentry/types';

type TagName = `axoform.${
  | 'application.id'
  | 'customer.id'
  | 'step'
  | 'step.name'
  | 'config.url'}`;

const setTag = (name: TagName, value: Primitive) => setSentryTag(name, value);

export default setTag;
